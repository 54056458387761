import React, { useState, useEffect } from 'react';
// import SR from 'scrollreveal';
import { Flex } from '../..';
import { BioModal } from '../../Modals/BioModal';
import { MoreButton } from '../../MoreButton';
import style from './style.module.scss';
//@ts-ignore
import { Fade } from 'react-reveal';

export interface ItemCardProps {
    data?: any;
    key?: any;
}

export const ItemCard = React.memo((props: ItemCardProps) => {
    const { data, key } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [hovered, setHovered] = useState(false);

    // useEffect(() => {
    //     SR().reveal(`#bio-card`, { opacity: 0.2, viewFactor: 0.3, scale: 0.9, delay: 0, reset: false, distance: '50px', origin: 'bottom' });
    // }, [])

    return (
        <div className={style.cardContainer} key={key}>
            <Fade bottom duration={500} distance="50px">
                <Flex id="bio-card"
                    className={style.item}
                    column
                    center
                    onClick={() => setIsOpen(true)}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}>
                    <div className={style.image_wrapper}>
                        <img
                            className={style.image}
                            draggable="false"
                            src={data?.Image?.url}
                            alt="bio_img" />
                    </div>
                    <Flex center column className={style.item_wrapper}>
                        <h3 style={{ padding: '5px  0', margin: 0 }}>{data?.FullName}</h3>
                        <h5 style={{ margin: 0 }}>{data?.Designations}</h5>
                        <div className={style.mainDivider} />
                        <h5 style={{ color: '#000', margin: 0, padding: '5px 0' }}>{data?.Hierarchy}</h5>
                        <h5 style={{ color: '#000', margin: 0, padding: '5px 0 0 0' }}>{data?.Title}</h5>
                        <Flex className={style.more_btn_wrapper}>
                            <MoreButton
                                hovered={hovered}
                                onClick={() => setIsOpen(true)} >
                                {`Read ${data?.FullName?.split(' ')[0]}'s Bio`}
                            </MoreButton>
                        </Flex>
                    </Flex>
                </Flex>
            </Fade>
            <BioModal isOpen={isOpen} toggleModal={setIsOpen} data={data} />
        </div>
    )
});
