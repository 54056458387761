import {
  GetBusinessServices,
  GetIndividualServices,
  GetAboutLandingSection,
  GetAboutSectionOne,
  GetAboutTeam,
  GetHomeLandingSection,
  GetHomeSectionOne,
  GetHomeSectionTwo,
  GetArticles,
  GetMainMetaTags
} from '../Constants/ContentContants';
import axios from 'axios';
import { Collections } from '../../Constants/ContentCollections';
import { ResolveEnvironment } from '../../Utils/EnvironmentHelpers';

// const buildCmsUrl = (collection: string) => ResolveEnvironment() === 'local' ? `http://localhost:1337/${collection}` : `https://sheltered-badlands-60147.herokuapp.com/${collection}`

// I just use this for dev purposes - Roman C.
const buildCmsUrl = (collection: string) => `https://eha-strapi-022c51a3f710.herokuapp.com/${collection}`

export const GetIndividualServicesAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetIndividualServices.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.individualServiceSection()))
      dispatch({ type: GetIndividualServices.Success, data })
    } catch (error) {
      dispatch({ type: GetIndividualServices.Failed, error })
    }
  }
}

export const GetArticlesAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetArticles.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.article()))
      dispatch({ type: GetArticles.Success, data })
    } catch (error) {
      dispatch({ type: GetArticles.Failed, error })
    }
  }
}

export const GetBusinessServicesAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetBusinessServices.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.businessServiceSection()))
      dispatch({ type: GetBusinessServices.Success, data })
    } catch (error) {
      dispatch({ type: GetBusinessServices.Failed, error })
    }
  }
}

// Single Types
export const GetAboutLandingSectionAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetAboutLandingSection.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.aboutLandingSection()))
      console.log(`GetAboutLandingSection response `, data);
      dispatch({ type: GetAboutLandingSection.Success, data })
    } catch (error) {
      dispatch({ type: GetAboutLandingSection.Failed, error })
    }
  }
}

export const GetAboutSectionOneAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetAboutSectionOne.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.aboutSectionOne()))
      console.log(`GetAboutSection response `, data);
      dispatch({ type: GetAboutSectionOne.Success, data })
    } catch (error) {
      dispatch({ type: GetAboutSectionOne.Failed, error })
    }
  }
}

export const GetAboutTeamAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetAboutTeam.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.aboutTeam()))
      console.log(`GetAboutTeam response `, data);
      dispatch({ type: GetAboutTeam.Success, data })
    } catch (error) {
      dispatch({ type: GetAboutTeam.Failed, error })
    }
  }
}

export const GetHomeLandingAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetHomeLandingSection.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.homeLandingSection()))
      console.log(`GetHomeLanding response `, data);
      dispatch({ type: GetHomeLandingSection.Success, data })
    } catch (error) {
      dispatch({ type: GetHomeLandingSection.Failed, error })
    }
  }
}

export const GetHomeSectionOneAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetHomeSectionOne.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.homeSectionOne()))
      console.log(`GetHomeSectionOne response `, data);
      dispatch({ type: GetHomeSectionOne.Success, data })
    } catch (error) {
      dispatch({ type: GetHomeSectionOne.Failed, error })
    }
  }
}

export const GetHomeSectionTwoAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetHomeSectionTwo.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.homeSectionTwo()))
      console.log(`GetHomeSectionTwo response `, data);
      dispatch({ type: GetHomeSectionTwo.Success, data })
    } catch (error) {
      dispatch({ type: GetHomeSectionTwo.Failed, error })
    }
  }
}

export const GetMainMetaTagsAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GetMainMetaTags.Loading })
      const { data } = await axios.get(buildCmsUrl(Collections.metaTag()))
      console.log(`GetMainMetaTagsAction response `, data);
      dispatch({ type: GetMainMetaTags.Success, data: data?.Tag })
    } catch (error) {
      dispatch({ type: GetMainMetaTags.Failed, error })
    }
  }
}